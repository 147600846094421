
<template>
 <CContainer>
    <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <h3>Contenido Multimedia Ayuda</h3>
        </CCardHeader>
        <CCardBody>
          <div class="container">
            <div class="row">
              <div class="col-8">
                <div class="embed-responsive embed-responsive-16by9">
                  <iframe width="560" height="315" :src="urlMultimediaPrincipal" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen id="principal_video_multimedia"></iframe>
                </div>
              </div>
              <div class="col-4">
                 <CListGroup style="overflow-x: hidden;overflow-y:scroll;max-height: 360px;">
                    <CListGroupItem v-for="(item, index) in dataMultimedia.videos" v-bind:data="item" v-bind:key="item.nombre" href="#" @click="abriNuevoVideo(item.url)" class="flex-column align-items-start text-center" >
                      <div class="text-center">
                        <h5 class="mb-1">{{item.nombre}}</h5>
                      </div>
                      <img  :src="'https://img.youtube.com/vi/'+item.url+'/0.jpg'" alt="" style="width:70%">
                    </CListGroupItem>
                  </CListGroup>
              </div>
            </div>
          </div>
      </CCardBody>
      </CCard>
      </CCol>
      </CRow>

      <CRow>
          <CCol col="12">
              <CCard>
                  <CCardHeader>
                    <h3>Documentación y Formatos</h3>
                  </CCardHeader>
                  <CCardBody>
                    <CRow>
                      <CCol col="3" v-for="(item, index) in dataMultimedia.archivos" v-bind:data="item" v-bind:key="item.nombre">
                        <div class="card mb-4 rounded-3 shadow-sm">
                          <div class="card-header py-3">
                            <h4 class="my-0 fw-normal">{{item.nombre}}</h4>
                          </div>
                          <div class="card-body">
                            <p>{{item.descripcion}}</p>
                            <a :href="item.url" target="_blank" class="w-100 btn btn-lg btn-outline-primary">Descargar Archivo</a>
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                </CCardBody>
              </CCard>
            </CCol>
      </CRow>
  </CContainer>
</template>
<script>

export default {
  name: 'Contenido Ayuda',
  components: {},
  data () {
    return {
      dataMultimedia:[],
      urlMultimediaPrincipal:'',
    }
  },
  methods: {
    obtenerDatosMultimedia(){
      this.$http.get('/sms/ayuda/multimedia').then(response => {
            this.dataMultimedia = response.data;
            if (this.dataMultimedia.videos.length > 0) {
              this.urlMultimediaPrincipal= `https://www.youtube.com/embed/${this.dataMultimedia.videos[0].url}`;
              document.getElementById('principal_video_multimedia').contentWindow.location.reload();
            }
        }).catch((e)=>{

        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    abriNuevoVideo(url){
      this.urlMultimediaPrincipal= `https://www.youtube.com/embed/${url}`;
      document.getElementById('principal_video_multimedia').contentWindow.location.reload();
    }
  },
  mounted () {
    setTimeout(() =>{
      this.obtenerDatosMultimedia();
    },100)
  }
}
</script>
<style>
.container.container1::-webkit-scrollbar {
  width: 7px;
  background: #000;
}
.container.container1::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 5px;
}
</style>
